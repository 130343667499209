import { authenticatedGet } from '../lib/authenticatedGet'
import { API_HOST } from '../lib/environment'
import { InstallationDto } from './Dtos'

//const COMPLETE_API_HOST: string = "http://localhost:4567/masterdata/installation-search"
const COMPLETE_API_HOST: string = API_HOST + "/installation-search"

export function fetchInstallations(queryParams: String): Promise<InstallationDto[]> {
    return authenticatedGet(`${COMPLETE_API_HOST}${queryParams}`).then((resp: any) => resp)
}

export function fetchInstallationsByQuery(queryParams: String): Promise<InstallationDto[]> {
    return authenticatedGet(`${COMPLETE_API_HOST}/query${queryParams}`).then((resp: any) => resp)
}
  
  