import React, { useState, useEffect, useReducer, useMemo } from 'react'
import Select from 'react-select'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Tooltip from '@material-ui/core/Tooltip'
import AddIcon from '@material-ui/icons/NoteAdd'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Popover from '@material-ui/core/Popover'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import { blue, red } from '@material-ui/core/colors'
import { ColumnHeaders } from './ColumnHeaders'
import DownshiftMultiple from './Suggestions'

const reducer = (state, action) => {
    switch (action.type) {
        case 'add':
            return {
                ...state,
                filters: [
                    ...state.filters.slice(0, action.pos),
                    action.item,
                    ...state.filters.slice(action.pos),
                ]
            }
        case 'remove':
            return {
                ...state,
                filters: state
                    .filters
                    .filter(item => item.label !== action.item.label)
            }
        case 'update':
            return {
                ...state,
                filters: state.filters.map(filter => filter.label === action.oldName ? {
                    ...filter, 
                    value: action.newFilter.label,
                    label: action.newFilter.label,
                    queries: action.newFilter.queries
                } : filter)
            }
        case 'chainName': 
            return { 
                ...state,
                queries: {
                    ...state.queries,
                    chainName: action.item
                }
            }
        case 'clearingHouse': 
            return { 
                ...state,
                queries: {
                    ...state.queries,
                    clearingHouse: action.item
                }
            }
        case 'sourceID': 
            return { 
                ...state,
                queries: {
                    ...state.queries,
                    sourceID: action.item
                }
            }
        case 'storeName': 
            return { 
                ...state,
                queries: {
                    ...state.queries,
                    storeName: action.item
                }
            }
        case 'region': 
            return { 
                ...state,
                queries: {
                    ...state.queries,
                    region: action.item
                }
            }
        case 'zipCode': 
            return { 
                ...state,
                queries: {
                    ...state.queries,
                    zipCode: action.item
                }
            }
        case 'address': 
            return { 
                ...state,
                queries: {
                    ...state.queries,
                    address: action.item
                }
            }
        case 'city': 
            return { 
                ...state,
                queries: {
                    ...state.queries,
                    city: action.item
                }
            }
        case 'countryCode': 
            return { 
                ...state,
                queries: {
                    ...state.queries,
                    countryCode: action.item
                }
            }
        case 'materialGroup': 
            return { 
                ...state,
                queries: {
                    ...state.queries,
                    materialGroup: action.item
                }
            }
        case 'serialNumber': 
            return { 
                ...state,
                queries: {
                    ...state.queries,
                    serialNumber: action.item
                }
            }
        default: 
            return state
    }
}

export default function CustomFilters(props) {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)
    const [edit, setEdit] = useState(false)
    const [open, setOpen] = useState(false)
    const [alert, setAlert] = useState(false)
    const [duplicateFilterName, setDuplicateFilterName] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [currentFilter, setCurrentFilter] = useState(null)
    const [allData, setAllData] = useState([])
    const [state, dispatch] = useReducer(reducer, {
        filters: [],
        queries: {
            chainName: [],
            clearingHouse: [],
            sourceID: [],
            storeName: [],
            region: [],
            zipCode: [],
            address: [],
            city: [],
            countryCode: [],
            materialGroup: [],
            serialNumber: [],
        }
    })
    
    useEffect(() => {
        //setLabelWidth(inputLabel.current.offsetWidth)
        if (localStorage.getItem('filters') === null) {
            localStorage.setItem('filters', "[]")
        }
        handleLocalStorage('', 'addToState')
    }, [])

    function handleChange(selectedFilter) {
        setFilterAndSearch(selectedFilter)
    }

    function setFilterAndSearch(curFil) {
        setCurrentFilter(curFil)
        // Prompt search from App.js when filter is chosen from list
        if(curFil !== null){
            console.log(curFil)
            props.onSearched(curFil.queries, 'filterSearch')
        } else {
            console.log(curFil)
            props.onSearched([], 'filterSearch')
        }
    }

    function handleInput(event) {
        setInputValue(event.target.value)
    }

    function handleDelete() {
        dispatch({type: 'remove', item: currentFilter})
        handleLocalStorage(currentFilter, 'remove')
        setFilterAndSearch(null)
        handleAlertClose()
    }

    function handleAdd(event) {
        if (allData.length === 0) {
            handleData()
        }
        setDuplicateFilterName(false)
        setAnchorEl(event.currentTarget)
        setOpen(true)
    }

    function closeAdd() {
        setEdit(false)
        setAnchorEl(null)
        setOpen(false)
        for (let header of ColumnHeaders) {
            dispatch({type: header.name, item: []})
        }
    }

    function exit(op) {
        if(op === 'apply') {
            if (!edit) {
                for (let f of state.filters) {
                    if (inputValue === f.label) {
                        setDuplicateFilterName(true)
                        return null
                    }
                }
            }

            let temp = []
            for (var i in ColumnHeaders) {
                if (ColumnHeaders[i].name !== 'locationId' && ColumnHeaders[i].name !== 'id') {
                    let query = state.queries[ColumnHeaders[i].name]
                    if (query.length !== 0){
                        temp = temp.concat(query)
                    }
                }
            }
            let newFilter = {value: inputValue, label: inputValue, queries: temp}
            if (!edit) {
                handleLocalStorage(newFilter, 'add')
                dispatch({type: 'add', item: newFilter, pos: 0})
            } else {
                handleLocalStorage([currentFilter, newFilter], 'update')
                dispatch({type: 'update', 
                    newFilter: newFilter, 
                    oldName: currentFilter.label})
                setFilterAndSearch(newFilter)
            }
        }
        closeAdd()
        }

    function handleLocalStorage(filter, action, ) {
        var filters = JSON.parse(localStorage.getItem('filters'))
        switch (action) {
            case 'add':
                filters.push(filter)
                break
            case 'remove':
                filters = filters.filter(item => item.label !== filter.label)
                break
            case 'update':
                filters.forEach(f => {
                    if (f.label === filter[0].label) {
                        f.value = filter[1].value
                        f.label = filter[1].label
                        f.queries = filter[1].queries
                    }
                })
                break
            case 'addToState':
                for (let f of filters) {
                    dispatch({type: 'add', item: f, pos: 0})
                }
                break
            default:
                return filters
        }
        localStorage.setItem('filters', JSON.stringify(filters))
    }

    function handleAlertOpen() {
        setAlert(true)
    }

    function handleAlertClose() {
        setAlert(false)
    }

    function handleEdit(e) {
        setEdit(true)
        setInputValue(currentFilter.value)
        handleAdd(e)
    }

    function handleData() {
        let tempObj = {
            chainName: [],
            clearingHouse: [],
            sourceID: [],
            storeName: [],
            region: [],
            zipCode: [],
            address: [],
            city: [],
            countryCode: [],
            materialGroup: [],
            serialNumber: [],
        }
        for (let header of ColumnHeaders) {
            let tempArr = []
            if (tempObj[header.name] !== undefined) {
                for (let elem of props.data) {
                    if (elem[header.name] !== null) {
                        tempArr.push(elem[header.name])
                    }
                }
            tempObj[header.name].push([...new Set(tempArr)])
            }
        }
        setAllData(tempObj)
    }

    const onSearched = (queries, key) => {
        /**
         * TODO: change all fields in state to one single query array
         * that is pushed and popped from. This is instead of saving all
         * values in separate fields and then piecing it back together 
         * before sending it upstream
         */
        if (queries.length === 0) {
            dispatch({type: key, item: []})
        } else {
            let split = queries[0].split(':')
            dispatch({type: split[0], item: queries})
        }
    }

    const getSearchQuery = (queries) => {
    }

    const id = open ? 'simple-popover' : undefined

    const customStyles = useMemo(() => ({
        control: (base, state) => ({
          ...base,
          margin: '25px',
          fontSize: '16px',
          color: props.theme === 'dark' ? 'white' : 'black',
          marginRight: 0,
          height: 'calc(100px - 2*25px)',
          background: props.theme === 'dark' ? '#424242' : '#fafafa',
          borderRadius: 4,
          borderColor: state.isFocused ? blue[400] : "grey",
          boxShadow: state.isFocused ? null : null,
          "&:hover": {
            transition: '300ms',
            borderColor: blue[400]
          }
        }),
        menu: base => ({
          ...base,
          borderRadius: 4,
          marginTop: 0,
          marginLeft: '25px',
          marginRight: '200px'
        }),
        menuList: base => ({
          ...base,
          background: props.theme === 'dark' ? "#424242" : '#fafafa',
          padding: 0,
        }),
        indicatorSeparator: base => ({
            ...base,
            background: 'grey',
        }),
        input: base => ({
          color: props.theme === 'dark' ? 'white' : 'black',
        }),
        option: (base, state) => ({
          ...base,
          color: state.isSelected 
            ? props.theme === 'dark' ? 'white' : 'black'
            : props.theme === 'dark' ? 'white' : 'black',
          backgroundColor: state.isFocused 
            ? props.theme === 'dark' ? "#424242" : '#f3f3f3'
            : props.theme === 'dark' ? "#3e3e3e" : '#fafafa',
          "&:active": {
            backgroundColor: props.theme === 'dark' ? "#424242" : '#fafafa',
          },
        }),
        singleValue: (base, state) => ({
            color: props.theme === 'dark' ? 'white' : 'black',
        })
    
      }), [props.theme])

    return (
        <div>
            <div className={classes.container}>
                <div className={classes.leftItem}>
                    <Select
                        value={currentFilter}
                        onChange={handleChange}
                        placeholder="Custom filter list"
                        isClearable
                        options={state.filters}
                        styles={customStyles}
                    />
                </div>
                <div className={classes.rightItem}>
                    <Tooltip title={"Add new filter"}>
                        <IconButton 
                            className={classes.iconButton} 
                            onClick={(e) => handleAdd(e)}>
                            <AddIcon className={classes.icon} />
                        </IconButton>
                    </Tooltip>
                    <Divider className={classes.smallDivider} />
                    <Tooltip title={"Delete selected filter"}>
                        <IconButton 
                            disabled={currentFilter === null} 
                            className={classes.iconButtonDelete} 
                            onClick={() => handleAlertOpen()}>
                            <DeleteIcon className={classes.icon} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Edit selected filter"}>
                        <IconButton 
                            disabled={currentFilter === null} 
                            className={classes.iconButtonDelete} 
                            onClick={(e) => handleEdit(e)}>
                            <EditIcon className={classes.icon} />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                className={classes.popover}
                onClose={() => closeAdd()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                    }}
            >
                <div className={classes.popup}>
                    <TextField 
                        label='Name'
                        placeholder='My filter'
                        defaultValue={edit ? currentFilter.label : ""}
                        className={classes.filterField}
                        helperText='Enter the name of your filter'
                        onChange={(e) => {handleInput(e)}}
                    /> 
                    <Divider className={classes.divider} />
                    {allData.length !== 0 &&
                        ColumnHeaders.map((header, index) => {
                            if (allData[header.name] !== undefined) {
                                return (
                                    <DownshiftMultiple 
                                        key={index}
                                        name={header.name}
                                        data={allData[header.name][0]} 
                                        onSearched={onSearched}
                                        getSearchQuery={getSearchQuery}
                                        placeholder={header.label}
                                        margin={'30px 0px 30px 30px'}
                                        width={'calc(50% - 2*30px)'}
                                        edit={edit}
                                        editData={currentFilter !== null ? currentFilter.queries : []}
                                    />
                                )
                            }
                        })
                    }
                    <Divider className={classes.divider} />
                    {duplicateFilterName && <Typography variant="subtitle2" className={classes.warning}>
                            A filter with that name already exists. Please use another name.
                        </Typography>}
                    <Button  
                        size="small" 
                        onClick={() => exit('apply')}
                        className={classes.button}>
                        <Typography variant="subtitle1" className={classes.title}>
                            Apply
                        </Typography>
                    </Button>
                    <Button  
                        size="small" 
                        style={{color: blue[400]}}
                        onClick={() => exit('cancel')}
                        className={classes.button}>
                        <Typography variant="subtitle1" className={classes.title}>
                            Cancel
                        </Typography>
                    </Button>
                </div>
            </Popover>
            <Dialog
                open={alert}
                onClose={() => handleAlertClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete this filter?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={() => handleAlertClose()} style={{color: blue[400]}}>
                        Cancel
                    </Button>
                    <Button onClick={() => handleDelete()} style={{color: red[400]}} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {/** TODO: make button for clearing current filter */}
        </div>        
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '0px',
    },
    formControl: {
        margin: '21.5px',
        marginRight: 0,
        [theme.breakpoints.down('lg')]: {
            minWidth: '60%'
        },
        [theme.breakpoints.up('xl')]: {
            minWidth: '70%'
        },
        //minHeight: 'calc(100px - 2*20px)',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    iconButton: {
        float: 'right',
        margin: 'calc((100px - 2em)/2)',
        marginLeft: 0,
    },
    iconButtonDelete: {
        float: 'right',
        margin: 'calc((100px - 2em)/2)',
        marginLeft: 0,
        marginRight: 0,
    },
    icon: {
    },
    filterField: {
        flexGrow: 1,
        margin: '30px',
        width: 'calc(50% - 2*30px)',
    },
    button: {
        margin: '20px 0px 30px 30px',
    },
    popover: {
        flexGrow: 1,
        width: '90%',
        height: '90%',
    },
    popup: {
        //flexGrow: 1,
        backgroundColor: theme.palette.type === 'dark' ? '#303030' : '#fafafa',
        width: 'auto',
        height: '90%',
        overflowY: 'hidden',
    },
    divider: {
        height: 1,
        width: 'calc(100% - 2*20px)',
        margin: '0px 20px 0px 20px',
    },
    smallDivider: {
        float: 'right',
        height: '30px',
        width: 2,
        margin: 'calc((100px - 2em)/2) 5px calc((100px - 2em)/2) 5px',
    },
    warning: {
        marginLeft: '30px',
    },
    container: {
        display: 'grid',
        maxHeight: '100px',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: theme.spacing(1),
    },
    leftItem: {
        gridColumnEnd: 'span 8',
        [theme.breakpoints.down('lg')]: {
            gridColumnEnd: 'span 7',
        },
    },
    rightItem: {
        gridColumnEnd: 'span 4',
        [theme.breakpoints.down('lg')]: {
            gridColumnEnd: 'span 5',
        },
    }
}))