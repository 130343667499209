type EnvConfig = {
    ENV_NAME: string
    KEYCLOAK_HOST: string
    LOCALE: string
    TIME_ZONE: string
}
const fallbackConfig: EnvConfig = {
    ENV_NAME: 'missing',
    KEYCLOAK_HOST: 'missing',
    LOCALE: 'missing',
    TIME_ZONE: 'missing'
}
// tslint:disable-next-line: no-eval
export const ENV_CONFIG = eval(process.env.REACT_APP_ENVJS || '') || fallbackConfig
export const ENVIRONMENT = ENV_CONFIG.ENV_NAME
export const environmentUrl = ENVIRONMENT.replace('-', '.')
export const API_HOST = `https://api.${environmentUrl}.tomra.cloud/masterdata`
export const KEYCLOAK_HOST = ENV_CONFIG.KEYCLOAK_HOST
export const LOCALE = ENV_CONFIG.LOCALE
export const TIME_ZONE = ENV_CONFIG.TIME_ZONE
export const VERSION = process.env.REACT_APP_VERSION
  