import React, { useState, useEffect, useReducer } from 'react'
import './App.css'
import { makeStyles } from '@material-ui/styles'
import HomeHeader from './components/HomeHeader'
import FancyContainer from './components/installations-overview/FancyContainer'
import ActionBar from './components/ActionBar'
import ErrorBoundary from './components/ErrorBoundary'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { fetchInstallations, fetchInstallationsByQuery } from './services/InstallationsService'

const useStyles = makeStyles({
  app: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
})

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
  },
})

const lightTheme = createMuiTheme({
  palette: {
    type: 'light',
    background: {
      default: "#cfd8dc"
    },
  },
})

const initialState = {
  allData: [],
  searchData: [],
  limitedData: [],
  initiated: false,
  isLoading: false,
  shouldRemoveChips: false,
  totalRows: 0,
  queryString: ""
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'init':
      return {
        ...state,
        initiated: true,
        isLoading: true
      }
    case 'dataInit':
      return {
        ...state, 
        allData: action.data,
        searchData: action.data,
        limitedData: action.data.slice(0,100),
        totalRows: action.data.length,
        isLoading: false
      }
    case 'searched':
      return {
        ...state,
        searchData: action.data,
        limitedData: action.data.slice(0,100),
        totalRows: action.data.length,
        shouldRemoveChips: action.shouldRemoveChips,
        isLoading: false,
        queryString: action.queryString,
      }
    case 'clickSearch':
      return {
        ...state,
        searchData: action.data,
        limitedData: action.data.slice(0,100),
        totalRows: action.data.length,
        isLoading: false,
        queryString: action.queryString
      }
    case 'startLoading':
      return {
        ...state,
        searchData: [],
        limitedData: [],
        isLoading: true,
        shouldRemoveChips: false,
      }
    case 'reset':
      return {
        ...state,
        searchData: state.allData,
        limitedData: state.allData.slice(0,100),
        totalRows: state.allData.length,
        isLoading: false,
        queryString: "",
        shouldRemoveChips: true,
      }
    default:
      break
  }
}

function App(props) {
  const classes = useStyles()
  const [state, dispatch] = useReducer(reducer, initialState)
  const [theme, setTheme] = useState(localStorage.getItem('theme') === 'light' 
    ? lightTheme : darkTheme)

  function toggleTheme() {
    const newTheme = theme === darkTheme ? lightTheme : darkTheme
    localStorage.setItem('theme', newTheme.palette.type)
    setTheme(newTheme)
  }

  function generateQueryString(queries) {
    /** 
     * Must be in correct sequence (although not all params are needed):
     * ?input=foo&queries1=bar&queries2=baz&...&offset=0&limit=100
     */
    let queryString = "?"
    for (var i in queries) {
      let split = queries[i].split(":")
      queryString += split[0] + "=" + split[1] + (i < queries.length - 1 ? "&" : "")
    }
    return queryString !== "?" ? queryString : ""
  }

  const onSearched = (queries, action) => {
    dispatch({type: 'startLoading'})
    const queryString = generateQueryString(queries)
    const isEmpty = queries.length === 0
    const fetch = isEmpty 
      ? fetchInstallations("?limit=0") 
      : fetchInstallationsByQuery(queryString)
    fetch.then(result => {
      isEmpty ?
      dispatch({type: 'reset'}) :  
      dispatch({type: 'searched', 
        data: result.data, 
        shouldRemoveChips: (action === 'filterSearch' ? true : false),
        queryString: queryString})
    })
  }

  const searchedClicked = (input, queries) => {
    dispatch({type: 'startLoading'})
    const queryString = generateQueryString(["query:" + input, ...queries])
    const isEmpty = queries.length === 0 && input.length === 0
    const fetch = isEmpty
      ? fetchInstallations("?limit=0") 
      : fetchInstallationsByQuery(queryString)
    fetch.then(result => {
      isEmpty ?
      dispatch({type: 'reset'}) :
      dispatch({type: 'clickSearch',
       data: result.data,
       queryString: queryString})
    })
  }
  
  useEffect(() => {
    dispatch({type: 'init'})
    fetchInstallations("").then(result => {
      dispatch({type: 'dataInit', data: result.data})
    })
  }, [])

  return (
    <ErrorBoundary>
      <MuiThemeProvider theme={theme}>
        <div className={classes.app}>
          <CssBaseline />
          <HomeHeader 
            onToggleTheme={() => toggleTheme()}
            doLogout={props.doLogout}
            theme={theme.palette.type}
            />
          <ActionBar 
            data={state.searchData}
            allData={state.allData} 
            className={classes.actionbar}
            onSearched={onSearched}
            onSearchClicked={searchedClicked}
            shouldRemoveChips={state.shouldRemoveChips}
            theme={theme.palette.type}
            />
          <FancyContainer 
            queryString={state.queryString}
            searchData={state.limitedData}
            totalRows={state.totalRows}
            theme={theme.palette.type} 
            className={classes.container}
            isLoading={state.initiated ? state.isLoading : true}
            isInitiated={state.initiated}
            />
        </div>
      </MuiThemeProvider>
    </ErrorBoundary>
  );
}


export default App;
