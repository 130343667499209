import React from 'react'
import { environmentUrl } from '../../lib/environment'
import LinkIcon from '@material-ui/icons/Assignment'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

const headers = ['chainName:Chain',
  'clearingHouse:Clearing house',
  'sourceID:Store ID',
  'storeName:Store name',    
  'region:Region',
  'zipCode:Zip',
  'address:Address',
  'city:City',
  'countryCode:Country',
  'materialGroup:Material group',
  'serialNumber:Serial nr',
  'locationId',
  'id:ID']

export function generateHeaders() {
  checkAndFillLocalStorage()
  let temp = []
  for (var i in headers) {
    if(headers[i] !== 'locationId'){
      let split = headers[i].split(':')
      temp.push({
        name: split[0],
        label: split[1],
        options: {
          display: split[0] === 'id' ? false : 
            localStorage.getItem(split[0]) === 'add' ? true : false
        },
      })
    } else {
      temp.push({
        name: headers[i],
        label: " ",
        options: {
          download: false,
          print: false,
          searchable: false,
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
              const ref = "https://dashboard." + environmentUrl + ".tomra.cloud/en/" + value + "/status"
              return (
                <Tooltip title={"See status"}>
                  <IconButton 
                    href={ref}
                    target="_my_blank"
                    style={styles.button}
                    disableRipple={true}
                    color="inherit"
                    >
                    <LinkIcon />
                  </IconButton>
                </Tooltip>
              )
          } 
        },
      })
    }
  }

  return temp
}

function checkAndFillLocalStorage() {
  if (localStorage.getItem('chainName') === null){
    console.log("No headers in localStorage")
    for (var i in headers){
      let split = headers[i].split(':')
      localStorage.setItem(split[0], 'add')
    }
    localStorage.setItem('viewChanged', true)
  }
}

export const ColumnHeaders = generateHeaders()

const styles = {
  button: {
    margin: 0,
    padding: 0,
  }
}
