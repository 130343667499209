import { authenticatedFetch as fetch } from '@tomra/client-side-http-client'
import { authentication } from './authentication'

export const authenticatedFetch = fetch(authentication)

export function authenticatedGet(url: string) {
  const options = {
    method: 'GET',
  }

  return authenticatedFetch(`${url}`, options)
}
