import 'react-dots-loader/index.css'
import React, { useEffect, useReducer, useCallback, useMemo } from 'react'
import MUIDataTable from 'mui-datatables'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { ColumnHeaders } from './ColumnHeaders'
import Loader from 'react-dots-loader'
import { fetchInstallations, fetchInstallationsByQuery } from '../../services/InstallationsService'

const initialState = {
  data: [],
  rowsPerPage: localStorage.getItem('rowsPerPage') ? parseInt(localStorage.getItem('rowsPerPage')) : 10,
  currentPage: 0,
  columns: ColumnHeaders,
  isLoading: false,
  queryString: "?",
  sortingString: "",
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'init':
      return {
        ...state,
        data: action.data.slice(0, state.rowsPerPage),
        currentPage: 0,
        queryString: action.queryString,
        sortingString: ""
      }
    case 'pageChange':
      return {
        ...state,
        data: action.data, 
        currentPage: action.page,
        isLoading: false,
      }
    case 'rowsChange':
      return {
          ...state,
        data: action.data, 
        rowsPerPage: action.rows,
        currentPage: 0,
        isLoading: false
      }
    case 'startLoading':
      return {
        ...state,
        isLoading: true
      }
    case 'sort':
      return {
        ...state,
        data: action.data,
        sortingString: action.sortingString
      } 
    default:
      break
  }
}

function useDataProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState)
  useEffect(() => {
     if (props.isInitiated && !props.isLoading) {
      dispatch({type: 'init', 
          data: props.searchData,
          queryString: props.queryString ? props.queryString + "&" : "?"})
    } 
  }, [props.searchData, props.queryString, props.isInitiated, props.isLoading])

  const fetch = useCallback((str) => state.queryString !== "?"
  ? fetchInstallationsByQuery(state.queryString + str)
  : fetchInstallations("?" + str), [state.queryString])
    
  const changePage = useCallback((page) => {
    fetch("offset=" + page * state.rowsPerPage + "&limit=" + state.rowsPerPage + state.sortingString).then(result => {
     dispatch({type: 'pageChange', 
      data: result.data, 
      page: page})
    })
  }, [fetch, state.rowsPerPage, state.sortingString])
  
  const changeRowsPerPage = useCallback((rows) => {
    const offsetLimitString = "offset=" + 0 + "&limit=" + rows
    fetch(offsetLimitString + state.sortingString).then(result => {
      dispatch({type: 'rowsChange', 
        data: result.data,
        rows: rows})
    })
  }, [fetch, state.sortingString])

  const sortColumn = useCallback((col, dir) => {
    const offsetLimitString = "offset=" + state.currentPage * state.rowsPerPage + 
      "&limit=" + state.rowsPerPage
    const sortingString = "&column=" + col + 
      "&direction=" + dir.slice(0, dir === 'ascending' ? 3 : 4)
    fetch(offsetLimitString + sortingString).then(result => {
      dispatch({type: 'sort', data: result.data, sortingString: sortingString})
    })
  }, [fetch, state.currentPage, state.rowsPerPage])
    
  const options = useMemo(() => ({
    filterType: 'textField',
    selectableRows: 'none',
    rowsPerPageOptions: [10,15,50,100],
    search: false,
    print: false,
    download: false,
    filter: false,
    responsive: 'scroll',
    serverSide: true,
    count: props.totalRows,
    rowsPerPage: state.rowsPerPage,
    page: state.currentPage,
    textLabels: {
      body: {
          noMatch: props.isLoading ?
              <Loader distance={30}/> :
              'Sorry, there is no matching data to display',
      },
    },
    onColumnViewChange: (changedColumn, action) => {
      localStorage.setItem(changedColumn, action)
    },
    onChangePage: (currentPage) => {
      changePage(currentPage)
    },
    onChangeRowsPerPage: (numberOfRows) => {
      changeRowsPerPage(numberOfRows)
      localStorage.setItem('rowsPerPage', numberOfRows)
    },
    onColumnSortChange: (changedColumn, direction) => {
      sortColumn(changedColumn, direction)
    } 
  }), [props.totalRows, 
      state.currentPage, 
      state.rowsPerPage, 
      props.isLoading, 
      changePage, 
      changeRowsPerPage,
      sortColumn])

  return {
    options,
    state
  }
}

const getMuiTheme = (theme) => createMuiTheme({
  palette: {
    type: theme,
  },
  overrides: {
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: '650px'
      }
    },
    MUIDataTableHeadRow: {
      root: {
        position: 'relative',
        whiteSpace: 'nowrap',
        zIndex: -2,
      }
    },
    MUIDataTableBodyRow: {
      root: {
        '&:nth-child(odd)': { 
          backgroundColor: theme === 'dark' ? '#404040' : '#f3f3f3',
        }
      }
    },
  },
})

export default function FancyTable(props) {
  const { options, state } = useDataProvider(props)
  const muiTheme = useMemo(() => getMuiTheme(props.theme), [props.theme])
  return (
    <MuiThemeProvider theme={muiTheme}>
      <MUIDataTable
        title={<Typography variant="h6">
          Installations (Number of machines: {props.isLoading || state.isLoading ? <Loader size={10}/> : props.totalRows}) 
          </Typography>
        }
        data={props.isLoading ? [] : state.data }
        columns={state.columns}
        options={options}
      />
    </MuiThemeProvider>
    )
}