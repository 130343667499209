import React from 'react'
import { makeStyles } from '@material-ui/styles'
import FancyTable from './FancyTable'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles({
    paper: {
        margin: '15px',
        elevation: 0,
        overflowX: 'auto',
        overflowY: 'auto',
        //height: 'calc(80% - 15px)',
        width: 'calc(100% - 2*15px)',
        maxWidth: '100%',
        position: 'absolute',
        zIndex: 0,
    },
})

export default function FancyContainer(props) {
    const classes = useStyles()
    return (
        <Paper className={classes.paper}>
            <FancyTable  
                queryString={props.queryString}
                searchData={props.searchData}
                totalRows={props.totalRows}
                theme={props.theme}
                isLoading={props.isLoading}
                isInitiated={props.isInitiated}
            />
        </Paper>
    )

}