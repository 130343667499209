import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import deburr from 'lodash/deburr'
import Downshift from 'downshift'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import Chip from '@material-ui/core/Chip'


function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps

  return (
    <TextField
      className={classes.textField}
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  )
}

function renderSuggestion(suggestionProps) {
  const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps
  const isHighlighted = highlightedIndex === index
  /* Suggestions without key (key:value)
  const label = suggestion.split(':')[1]
   */
  const isSelected = (selectedItem || '').indexOf(suggestion) > -1

  return (
    <MenuItem
      {...itemProps}
      key={suggestion}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion}
    </MenuItem>
  )
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
}

function getSuggestions(value, suggestions, { showEmpty = false } = {}) {
  const inputValue = deburr(value.trim()).toLowerCase()
  const inputLength = inputValue.length
  let count = 0
  
  return inputLength === 0 && !showEmpty
    ? []
    : suggestions.filter(suggestion => {
        const keep =
          /* Exact suggestions
          count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;
         */

          /* Broad suggestions */
          count < 10 && suggestion.split(':')[1].toLowerCase().includes(inputValue)
        if (keep) {
          count += 1;
        }

        return keep;
  })
}



export default function DownshiftMultiple(props) {
  const classes = useStyles(props)
  const [inputValue, setInputValue] = useState('')
  const [selectedItem, setSelectedItem] = useState([])
  const [suggestions, setSuggestions] = useState([])

  function handleKeyDown(event) {
    if (selectedItem.length && !inputValue.length && event.key === 'Backspace') {
      const newSelectedItem = selectedItem.slice(0, selectedItem.length - 1)
      setSelectedItem(newSelectedItem)
      props.onSearched(newSelectedItem, props.name)
    }
  }

  function handleInputChange(event) {
    let val = event.target.value
    setInputValue(val)
    props.getSearchQuery(val)
  }

  function handleChange(item) {
    if (item !== null) {
      let newSelectedItem = [...selectedItem]
      if (newSelectedItem.indexOf(item) === -1) {
        newSelectedItem = [...newSelectedItem, item]
      }
      setInputValue('')
      setSelectedItem(newSelectedItem)
      props.onSearched(newSelectedItem, props.name)
    }
  }

  const handleDelete = item => () => {
    const newSelectedItem = [...selectedItem]
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1)
    setSelectedItem(newSelectedItem)
    props.onSearched(newSelectedItem, props.name)
  }
  
  useEffect(() => {
    if (props.edit) {
      for (let elem of props.editData) {
        if (elem.split(':')[0] === props.name) {
          handleChange(elem)
        }
      }
    } 
  }, [])

  useEffect(() => {
    /**
     * TODO: Instead of making a new suggestion list every time,
     * switch to useReducer and remove the items that are no longer
     * relevant to search 
     */
    
    if (props.shouldRemoveChips) {
      setSelectedItem([])
    }
    if (props.shouldRemoveInput) {
      setInputValue('')
    }

    // If all data should be suggested at all times
    let temp = []
    for (let row of props.data) {
      if(props.name){
        temp.push(props.name + ':' + row)
      } else {
        for (let [key, value] of Object.entries(row)) {
          if (value !== null && key !== 'locationId' && key !== 'id') {
            let str = key + ':' + value
            temp.push(str)
          }
        }
      }
    }
    setSuggestions(props.name ? temp : [...new Set(temp)])
  }, [props.data, props.name])

  return (
    <Downshift
      id="downshift-multiple"
      inputValue={inputValue}
      onChange={handleChange}
      selectedItem={selectedItem}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        isOpen,
        inputValue: inputValue2,
        selectedItem: selectedItem2,
        highlightedIndex,
      }) => {
        const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
          onKeyDown: handleKeyDown,
          placeholder: props.placeholder,
        })

        return (
          <div className={classes.container}>
            {renderInput({
              classes,
              label: props.label,
              InputLabelProps: getLabelProps(),
              InputProps: {
                startAdornment: selectedItem.map(item => (
                  <Chip
                    key={item}
                    tabIndex={-1}
                    label={item}
                    className={classes.chip}
                    onDelete={handleDelete(item)}
                  />
                )),
                onBlur,
                onChange: event => {
                  handleInputChange(event)
                  onChange(event)
                },
                onFocus,
              },
              inputProps,
            })}

            {isOpen ? (
              <Paper className={classes.paper} square>
                {getSuggestions(inputValue2, suggestions).map((suggestion, index) =>
                  renderSuggestion({
                    suggestion,
                    index,
                    itemProps: getItemProps({ item: suggestion }),
                    highlightedIndex,
                    selectedItem: selectedItem2,
                  }),
                )}
              </Paper>
            ) : null}
          </div>
        )
      }}
    </Downshift>
  )
}

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    container: (props) => ({
      flexGrow: 1,
      position: 'relative',
      top: props.top,
      margin: props.margin,
      display: 'inline-block',
      width: props.width,
    }),
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
      backgroundColor: theme.palette.type === 'dark' ? '#303030' : '#fafafa',
    },
    chip: {
      margin: theme.spacing(0.5, 0.25),
    },
    textField: {
      position: 'relative',
      width: '100%',
    },
    inputRoot: {
      flexWrap: 'wrap',
      width: 'auto',
    },
    inputInput: {
      position: 'relative',
      maxWidth: '100%',
      flexGrow: 1,
    },
  }))

/* DownshiftMultiple.propTypes = {
  classes: PropTypes.object.isRequired,
} */




