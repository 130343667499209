import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/styles'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import tomraArrow from '../images/tomra-arrow.png'
import ExitIcon from '@material-ui/icons/ExitToApp'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

const styleToolbar = makeStyles({
    
    ab: {
        flexGrow: 1,
        backgroundColor: 'black',
    },
    title: {
        flexGrow: 1,
        marginLeft: '20px',
    },
    logout: {
        color: 'white',
    }
})


function Header(props) {
    const classes = styleToolbar()

    function toggleTheme() {
        props.onToggleTheme()
    }

    return (
        <AppBar position="static" className={classes.ab}>
            <Toolbar>
                <img src={tomraArrow} height="30px" alt="TOMRA logo" />
                <Typography variant="h6" className={classes.title}>
                    Installation search
                </Typography>
                <Switch
                    checked={props.theme === 'light'}
                    onChange={() => toggleTheme()}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    right='15px'
                />
                <Tooltip title={"Logout"}>
                    <IconButton  
                        onClick={props.doLogout}>
                        <ExitIcon 
                            fontSize="large" 
                            //color="primary"
                            className={classes.logout}/>
                    </IconButton>
                </Tooltip>
            </Toolbar>
            
        </AppBar>
    )
}


export default Header