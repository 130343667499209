import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Search from '@material-ui/icons/Search'
import DownshiftMultiple from './installations-overview/Suggestions'
import CustomFilters from './installations-overview/CustomFilters'


export default function ActionBar(props) {
    const classes = useStyles()
    const [searchQuery, setSearchQuery] = useState('')
    const [chipAndFilterQueries, setChipAndFilterQueries] = useState([])
    const [activeFilter, setActiveFilter] = useState([])

    const searchClicked = () => {
        props.onSearchClicked(searchQuery, chipAndFilterQueries)
    }
    
    const getSearchQuery = (query) => {
        setSearchQuery(query)
    }

    const onSearched = (queries, key) => {
        if (key === 'filterSearch') {
            setActiveFilter(queries)
        } else if (activeFilter.length !== 0) {
            const clone = [...activeFilter]
            for (let q of queries) {
                clone.push(q)
            }
            queries = [...new Set(clone)]

        }
        setChipAndFilterQueries(queries)
        props.onSearched(queries, key)
        setSearchQuery('')
    }


    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <div className={classes.leftItem}>
                    <Paper className={classes.paper}>
                        <DownshiftMultiple 
                            data={props.data} 
                            onSearched={onSearched}
                            getSearchQuery={getSearchQuery}
                            label={'Search'}
                            placeholder={'Store name, serial number, machine model...'}
                            top={'12px'}
                            margin={'20px 0px 20px 20px'}
                            width={'calc(100% - 2*20px)'} 
                            shouldRemoveChips={props.shouldRemoveChips}
                            shouldRemoveInput={searchQuery === ''}
                        />
                        <Tooltip title={"Search"}>
                            <IconButton 
                                className={classes.button} 
                                onClick={() => searchClicked()}
                            >
                                <Search className={classes.searchIcon} />
                            </IconButton>
                        </Tooltip>
                        
                    </Paper>
                </div>
                <div className={classes.rightItem}>
                    <Paper className={classes.actions}>
                        <CustomFilters 
                            data={props.allData} 
                            onSearched={onSearched}
                            theme={props.theme}
                        />
                    </Paper>
                </div>
            </div>

        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        flexGrow: 1,
        margin: '15px',
        minHeight: '100px',
        whiteSpace: 'nowrap',
        position: 'relative',
    },
    actions: {
        flexGrow: 1,
        margin: '15px',
        minHeight: '100px',
    },
    button: {
        //margin: '20px 20px 20px 0px',
        //float: 'right',
        position: 'absolute',
        bottom: '15px',
        right: '20px',
    },
    title: {
        flexGrow: 1,
    },
    textField: {
        flexGrow: 1,
        top: '12px',
        margin: '20px',
        width: '30%',
    },
    filterField: {
        flexGrow: 1,
        margin: '30px',
        width: 'calc(50% - 2*30px)',
    },
    popover: {
        flexGrow: 1,
        width: '75%',
    },
    popup: {
        flexGrow: 1,
        overflowY: 'hidden',
        backgroundColor: '#303030',
        width: '100%',
    },
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: theme.spacing(1),
    },
    leftItem: {
        gridColumnEnd: 'span 7',
        [theme.breakpoints.down('md')]: {
            gridColumnEnd: 'span 12',
        }
    },
    rightItem: {
        gridColumnEnd: 'span 5',
        [theme.breakpoints.down('md')]: {
            gridColumnEnd: 'span 6',
        },
    }
}))