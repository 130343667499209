import { AuthStore } from '@tomra/client-side-authentication'
import { KEYCLOAK_HOST } from './environment'
import { getLocaleForKeycloak } from './locale'

//const KEYCLOAK_HOST: string = 'https://id.eu.test.tomra.cloud/auth'
//const locale: string = 'en'

export const authentication = new AuthStore(
  'TomraConnectUsers',
  'installation-search-ui', 
  KEYCLOAK_HOST,
  getLocaleForKeycloak
)
