import React from 'react'
import ReactDOM from 'react-dom'
import { Alert } from '@tomra/shared-styled-components'
import { logError } from '@tomra/client-side-logging'
import './index.css'
import App from './App'
import { authentication } from './lib/authentication'
import { BrowserRouter } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'

//ReactDOM.render(<App />, document.getElementById('root'))

const Index = () => (
    <BrowserRouter>
      <App doLogout={authentication.logout} />
    </BrowserRouter>
)

const mountNode = document.getElementById('root')
if (!mountNode) {
  throw new Error('Could not get #root element')
}


authentication.initAndRequireAuthentication().then(
    () => {
      ReactDOM.render(<Index />, mountNode)
    },
    (err) => {
      err
        ? logError(new Error('Authentication failed'), err)
        : logError(new Error('Authentication failed'), new Error('Unknown error'))
  
      ReactDOM.render(<Alert type="danger">Wooopsie, authentication failed for some reason!</Alert>, mountNode)
    }
  )
  

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
